<template>
  <div>
    <div class="support-widget" v-b-modal.modal-support v-b-tooltip.hover title="Precisando de ajuda?">
      <div class="content">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-question-lg" viewBox="0 0 16 16">
          <path d="M3 4.075a.423.423 0 0 0 .43.44H4.9c.247 0 .442-.2.475-.445.159-1.17.962-2.022 2.393-2.022 1.222 0 2.342.611 2.342 2.082 0 1.132-.668 1.652-1.72 2.444-1.2.872-2.15 1.89-2.082 3.542l.005.386c.003.244.202.44.446.44h1.445c.247 0 .446-.2.446-.446v-.188c0-1.278.487-1.652 1.8-2.647 1.086-.826 2.217-1.743 2.217-3.667C12.667 1.301 10.393 0 7.903 0 5.645 0 3.17 1.053 3.001 4.075zm2.776 10.273c0 .95.758 1.652 1.8 1.652 1.085 0 1.832-.702 1.832-1.652 0-.985-.747-1.675-1.833-1.675-1.04 0-1.799.69-1.799 1.675z"/>
        </svg>
      </div>
    </div>

    <!-- size="lg"  -->
    <b-modal id="modal-support" title="Precisando de ajuda?"
     hide-footer>
     <!-- body-class="bg-dark" header-bg-variant="dark" footer-bg-variant="dark" footer-border-variant="dark" header-border-variant="dark" header-close-variant="light" -->
      <p>
        Você já viu o nosso FAQ?
        <a class="btn btn-sm btn-outline-info" href="/pages/faq">Visite agora</a>
      </p>

      <p>
        Chama no Twitter
        <a class="btn btn-sm btn-outline-info" href="https://twitter.com/tipa_ai" target="_blank">
          @tipa_ai
        </a>
      </p>

      <p>
        Fale com a gente por Email
        <a class="btn btn-sm btn-outline-info" href="mailto:team@locomotiva.info">
          team@locomotiva.info
        </a>
      </p>

      <p>
        Fale com a gente pelo Discord
        <a class="btn btn-sm btn-outline-info" href="https://bit.ly/tipadiscord" target="_blank">
          Nosso servidor no Discord
        </a>
      </p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'SupportWidget'
}
</script>

<style lang="scss" scoped>
.support-widget {
  position: fixed;
  bottom: 10px;
  right: 10px;

  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, .1);
  transition: all .2s;
  border-radius: 250%;

  padding: 0;
  margin: 0;

  line-height: 100%;

  cursor: pointer;

  outline: 0;

  svg {
    opacity: .6;
    transition: all .2s;
  }

  &:hover {
    background: rgba(255, 255, 255, .3);
    svg {
      opacity: 1;
    }
  }
}
</style>
