import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'

import NotFound from '../views/NotFound/index.vue'

const Dashboard = () => import(/* webpackChunkName: "dashboard-chunk" */'../views/Dashboard/index.vue')
const DashboardSubs = () => import(/* webpackChunkName: "dashboard-chunk" */'../views/Dashboard/Subs/index.vue')
const DashboardBook = () => import(/* webpackChunkName: "dashboard-chunk" */'../views/Dashboard/BookEntry/index.vue')
const Portal = () => import(/* webpackChunkName: "dashboard-chunk" */'../views/Portal/index.vue')

const Login = () => import(/* webpackChunkName: "auth-chunk" */'../views/Auth/Login/index.vue')
const Register = () => import(/* webpackChunkName: "auth-chunk" */'../views/Auth/Register/index.vue')

const ResendConfirmation = () => import(/* webpackChunkName: "auth-chunk" */'../views/User/ResendConfirmation/index.vue')
const ConfirmEmail = () => import(/* webpackChunkName: "auth-chunk" */'../views/User/ConfirmEmail/index.vue')

const ForgotPassword = () => import(/* webpackChunkName: "auth-chunk" */'../views/User/ForgotPassword/index.vue')
const ResetPassword = () => import(/* webpackChunkName: "auth-chunk" */'../views/User/ResetPassword/index.vue')

const ResendUnlock = () => import(/* webpackChunkName: "auth-chunk" */'../views/User/ResendUnlock/index.vue')
const Unlock = () => import(/* webpackChunkName: "auth-chunk" */'../views/User/Unlock/index.vue')

const PremiumSubs = () => import('../views/PremiumSubs/index.vue')

const DonationGoal = () => import('../views/Plugins/DonationGoal/index.vue')
const DonationGoalNew = () => import('../views/Plugins/DonationGoal/New/index.vue')
const DonationGoalEdit = () => import('../views/Plugins/DonationGoal/Edit/index.vue')

const PluginScrapbook = () => import('../views/Plugins/Scrapbook/index.vue')
const PluginSub = () => import('../views/Plugins/Sub/index.vue')

// const Settings = () => import('../views/Settings/index.vue')
// const SettingsNew = () => import('../views/Settings/new.vue')
const Settings = () => import('../views/Settings/index.vue')
const Preferences = () => import('../views/Settings/Preferences/index.vue')
const Bot = () => import('../views/Settings/Bot/index.vue')
const AppsDashboard = () => import('../views/Apps/Dashboard.vue')
const AppsTwitch = () => import('../views/Apps/Twitch.vue')
const Overlay = () => import('../views/Settings/Overlay/index.vue')
const Tipping = () => import('../views/Settings/Tipping/index.vue')

const OverlayEditor = () => import('../views/OverlayEditor/index.vue')

const CustomizeTemplate = () => import('../views/Customize/Template.vue')

const UserTipPage = () => import('../views/User/Tip/index.vue')
const UserTipPayment = () => import('../views/User/Tip/payment.vue')
const UserScrapbookPage = () => import('../views/User/ScrapbookPage/index.vue')

// const UserSubPage = () => import('../views/User/Sub')
// const UserSubPayment = () => import('../views/User/Sub/payment.vue')

const PagesTerms = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Terms.vue')
const PagesPrivacy = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Privacy.vue')
const PagesTaxes = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Taxes.vue')
const PagesWidgets = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Widgets.vue')
const PagesCustomization = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Customization.vue')
const PagesTipaSubs = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/TipaSubs.vue')
const PagesPremium = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Premium.vue')
const PagesApi = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Api.vue')
const PagesLandingStreamer = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Landing/Streamer.vue')
const PagesLandingChannel = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Landing/Channel.vue')
const PagesLandingPodcast = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Landing/Podcast.vue')
const PagesFaq = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Faq.vue')
const Blog = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Blog.vue')
const BlogView = () => import(/* webpackChunkName: "marketing-chunk" */'../views/BlogView.vue')
const PagesHome = () => import(/* webpackChunkName: "marketing-chunk" */'../views/Pages/Home.vue')

const AccountEdit = () => import('../views/Settings/Account/Edit/index.vue')
const AccountAlert = () => import('../views/Settings/Alert/index.vue')
const AccountUsername = () => import('../views/Settings/Account/Username/index.vue')

const Campaigns = () => import('../views/Campaigns/index.vue')
const NewCampaign = () => import('../views/Campaigns/New/index.vue')

const DiscordTicket = () => import('../views/DiscordTicket/index.vue')

const Shops = () => import('../views/Shops/index.vue')

Vue.use(VueRouter)

const routesUser = [
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { layout: 'marketing' }
  },
  {
    path: '/resend-confirmation',
    name: 'ResendConfirmation',
    component: ResendConfirmation,
    meta: { layout: 'auth' }
  },
  {
    path: '/confirm-email',
    name: 'ConfirmEmail',
    component: ConfirmEmail,
    meta: { layout: 'auth' }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { layout: 'auth' }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { layout: 'auth' }
  },
  {
    path: '/resend-unlock',
    name: 'ResendUnlock',
    component: ResendUnlock,
    meta: { layout: 'auth' }
  },
  {
    path: '/unlock',
    name: 'Unlock',
    component: Unlock,
    meta: { layout: 'auth' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { layout: 'auth' }
  }
]

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { layout: 'next' }
  },
  {
    path: '/dashboard/subs',
    name: 'DashboardSubs',
    component: DashboardSubs,
    meta: { layout: 'next' }
  },
  {
    path: '/dashboard/book',
    name: 'DashboardBook',
    component: DashboardBook,
    meta: { layout: 'next' }
  },
  {
    path: '/portal',
    name: 'Portal',
    component: Portal,
    meta: { layout: 'next' }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { layout: 'next' }
  },
  {
    path: '/settings/account',
    name: 'AccountEdit',
    component: AccountEdit,
    meta: { layout: 'next' }
  },
  {
    path: '/settings/alert',
    name: 'AccountAlert',
    component: AccountAlert,
    meta: { layout: 'next' }
  },
  {
    path: '/settings/change_username',
    name: 'AccountUsername',
    component: AccountUsername,
    meta: { layout: 'next' }
  },
  {
    path: '/settings/overlay',
    name: 'Overlay',
    component: Overlay,
    meta: { layout: 'next' }
  },
  {
    path: '/settings/overlays/:id',
    name: 'CustomizeTemplate',
    component: CustomizeTemplate,
    meta: { layout: 'next' }
  },
  {
    path: '/settings/apps',
    name: 'AppsDashboard',
    component: AppsDashboard,
    meta: { layout: 'next' }
  },
  {
    path: '/settings/apps/twitch',
    name: 'AppsTwitch',
    component: AppsTwitch,
    meta: { layout: 'next' }
  },
  {
    path: '/settings/preferences',
    name: 'Preferences',
    component: Preferences,
    meta: { layout: 'next' }
  },
  {
    path: '/settings/bot',
    name: 'Bot',
    component: Bot,
    meta: { layout: 'next' }
  },
  {
    path: '/premium_subs',
    name: 'PremiumSubs',
    component: PremiumSubs,
    meta: { layout: 'next' },
    props: {
      header: true,
      content: true
    }
  },
  {
    path: '/settings/tipping',
    name: 'Tipping',
    component: Tipping,
    meta: { layout: 'next' }
  },
  {
    path: '/settings/editor/:id?',
    name: 'OverlayEditor',
    component: OverlayEditor,
    meta: { layout: 'next' }
  },
  {
    path: '/plugins/donation_goal',
    name: 'DonationGoal',
    component: DonationGoal,
    meta: { layout: 'next' }
  },
  {
    path: '/plugins/donation_goal/new',
    name: 'DonationGoalNew',
    component: DonationGoalNew,
    meta: { layout: 'next' }
  },
  {
    path: '/plugins/donation_goal/:id',
    name: 'DonationGoalEdit',
    component: DonationGoalEdit,
    meta: { layout: 'next' }
  },
  {
    path: '/plugins/scrapbook',
    name: 'PluginScrapbook',
    component: PluginScrapbook,
    meta: { layout: 'next' }
  },
  {
    path: '/plugins/sub',
    name: 'PluginSub',
    component: PluginSub,
    meta: { layout: 'next' }
  },
  {
    path: '/tips/:id/payment',
    name: 'UserTipPayment',
    component: UserTipPayment,
    meta: { layout: 'default' }
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: Campaigns,
    meta: { layout: 'next' }
  },
  {
    path: '/campaigns/new',
    name: 'NewCampaign',
    component: NewCampaign,
    meta: { layout: 'next' }
  },
  {
    path: '/shops',
    name: 'Shops',
    component: Shops,
    meta: { layout: 'next' }
  }
]

const routesPublic = [
  {
    path: '/users/:id/scrapbook',
    name: 'UserScrapbookPage',
    component: UserScrapbookPage,
    meta: { layout: 'default' }
  },
  {
    path: '/pages/termos-de-uso',
    name: 'PagesTerms',
    component: PagesTerms,
    meta: { layout: 'marketing' }
  },
  {
    path: '/pages/tipa_subs',
    name: 'PagesTipaSubs',
    component: PagesTipaSubs,
    meta: { layout: 'marketing' }
  },
  {
    path: '/pages/privacidade',
    name: 'PagesPrivacy',
    component: PagesPrivacy,
    meta: { layout: 'marketing' }
  },
  {
    path: '/pages/taxas',
    name: 'PagesTaxes',
    component: PagesTaxes,
    meta: { layout: 'marketing' }
  },
  {
    path: '/pages/widgets',
    name: 'PagesWidgets',
    component: PagesWidgets,
    meta: { layout: 'marketing' }
  },
  {
    path: '/pages/customizacao',
    name: 'PagesCustomization',
    component: PagesCustomization,
    meta: { layout: 'marketing' }
  },
  {
    path: '/pages/premium',
    name: 'PagesPremium',
    component: PagesPremium,
    meta: { layout: 'marketing' }
  },
  {
    path: '/pages/api',
    name: 'PagesApi',
    component: PagesApi,
    meta: { layout: 'marketing' }
  },
  {
    path: '/p/streamer',
    name: 'PagesLandingStreamer',
    component: PagesLandingStreamer,
    meta: { layout: 'marketing' }
  },
  {
    path: '/p/canal',
    name: 'PagesLandingChannel',
    component: PagesLandingChannel,
    meta: { layout: 'marketing' }
  },
  {
    path: '/p/podcast',
    name: 'PagesLandingPodcast',
    component: PagesLandingPodcast,
    meta: { layout: 'marketing' }
  },
  {
    path: '/pages/faq',
    name: 'PagesFaq',
    component: PagesFaq,
    meta: { layout: 'marketing' }
  },
  {
    path: '/blog/:id',
    name: 'BlogView',
    component: BlogView,
    meta: { layout: 'marketing' }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
    meta: { layout: 'marketing' }
  },
  {
    path: '/',
    name: 'PagesHome',
    component: PagesHome,
    meta: { layout: 'marketing' }
  },
  {
    path: '/discord-ticket/:id',
    name: 'DiscordTicket',
    component: DiscordTicket,
    meta: { layout: 'default' }
  }
]

const routeNotFound = [{
  path: '/404',
  name: 'NotFound',
  component: NotFound,
  meta: { layout: 'default' }
},
{
  path: '/:id',
  name: 'UserPage',
  component: UserTipPage,
  meta: { layout: 'default' }
  // meta: { layout: 'marketing' }
},
{
  path: '/:id/shop',
  name: 'UserPageShop',
  component: UserTipPage,
  meta: { layout: 'default' }
  // meta: { layout: 'marketing' }
},
{
  path: '/:id/scrapbook',
  name: 'ScrapbookPage',
  meta: { layout: 'default' },
  component: UserScrapbookPage
},
{
  path: '/:id/sub',
  name: 'UserPageSub',
  component: UserTipPage,
  meta: { layout: 'default' }
},
{
  path: '*',
  name: 'NotFound',
  component: NotFound,
  meta: { layout: 'default' }
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes, ...routesUser, ...routesPublic, ...routeNotFound]
})
// scrollBehavior (to, _, savedPosition) {
//   if (to.hash) {
//     return { selector: to.hash, behavior: 'smooth' }
//   } else if (savedPosition) {
//     return savedPosition
//   } else {
//     return { x: 0, y: 0 }
//   }
// }

const routesPrivate = routes.map((r) => r.name)
const routesAuth = routesUser.map((r) => r.name)

router.beforeEach((to, from, next) => {
  try {
    window.scrollTo(0, 0)
  } catch (_) { }

  if (to.fullPath.includes('users') && ['overlay', 'qrcode', 'goals', 'overlays'].filter((r) => to.fullPath.includes(r))) {
    window.location.href = `${process.env.VUE_APP_OVERLAYS_URL}${to.fullPath}`
  }

  if (routesPrivate.includes(to.name) && !store.getters['user/loggedIn']) {
    next({ name: 'Login' })
  } else if ((routesAuth.includes(to.name)) && store.getters['user/loggedIn']) {
    next({ name: 'Home' })
  } else {
    next()
  }
})

export default router
